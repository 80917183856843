import Link from "next/link";

export default function ContactUsSection() {
  return (
    <section
      id="section-call-to-action"
      className="pt60 pb60 text-dark bg-[#fff]"
    >
      <div className="container ">
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="">
            <h2 className="text-[28px] lg:text-[40px]">
              Call us for further information.
            </h2>
            <h4 className="text-[20px]">
              MT EVEREST LIMO LLC care is here to help you anytime.
            </h4>
          </div>

          <div className=" text-lg-end text-sm-center contact-content">
            <div className="phone-num-big flex md:block   justify-between flex-row  md:flex-col gap-1">
              <span className="pnb-text">Call Us Now</span>
              <a href="tel:4153092068">
                <span className="text-[20px] lg:text-[28px] font-semibold">
                  +1 415-309-2068
                </span>
              </a>
            </div>

            <Link className="btn-main mt-2" href={"/contact"}>
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
