import Image from "next/image";
import Link from "next/link";

export default function Banner(){
    return(
      <div className="bg-[#000]">
          <div className="container ">
            <div className="flex justify-between items-end gap-3  relative">
        <Image width={1400} height={400}  src='/images/cars/car2.png' className="rounded-[4px] min-h-[200px]" alt="a"/>
        <div className="absolute left-10 flex flex-col bottom-6 md:bottom-16">
            <div className="flex flex-col  ">
            <h1 className="text-[20px] md:text-[40px] tracking-wide leading-tight capitalize text-[#fff] font-medium max-w-[450px]">
            Not Sure What You are Looking For?
          </h1>
          <p className="text-[14px] md:text-[16px] text-[#ffffff] tracking-wide">
          We will Help you clear all your Confusions.
          </p>
          <Link className="bg-[#cf9e2a] text-[#fff] text-[14px] md:text-[18px] font-semibold mt-2 w-fit px-4 py-1 rounded-[4px]" href='/contact'>Contact Us</Link>
            </div>
          </div>
      </div>
        </div>
      </div>
    )
}