import { LatLngType } from "@/types/model.types";
import { Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

type PlacesAutoCompleteProps = {
  placeholder?: string | null;
  type?: "pickup" | "dropoff" | "stops" | "returndropoff";
  defaultLocations?: { label: string; value: string }[];
  value?: any | null;
  size?: SizeType;
  width?: string;
  defaultOption?: any | null;
  // eslint-disable-next-line no-unused-vars
  onSelected?: (val: LatLngType, selectedData: object) => void | null;
};

export default function PlacesAutoComplete({
  placeholder = "",
  onSelected,
  value = null,
  defaultOption = null,
  size = "large",
  width,
  type = "pickup",
  defaultLocations = [],
}: PlacesAutoCompleteProps) {
  const {
    setValue,
    suggestions: { data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      bounds: {
        south: 24.396308,
        west: -125.0,
        north: 49.384358,
        east: -66.93457,
      },
    },
  });
  const optionList =
    data.length > 0
      ? data.map((place) => ({
          value: place.place_id,
          label: place.description,
        }))
      : defaultLocations;
  return (
    <Select
      value={value}
      // showArrow={false}
      defaultValue={defaultOption}
      filterOption={false}
      defaultActiveFirstOption={false}
      notFoundContent={null}
      onChange={async (address) => {
        const selectedData = optionList.find((d) => d.value === address);
        setValue(address, false);
        clearSuggestions();
        try {
          const results = await getGeocode({
            placeId: address,
          });
          const { lat, lng } = getLatLng(results[0]);
          if (onSelected) {
            onSelected({ lat, lng }, {
              description: selectedData?.label,
              place_id: selectedData?.value,
            } as object);
          }
        } catch (error) {}
      }}
      options={optionList}
      showSearch
      size={size}
      style={{ width: width ?? "95%", overflow: "hidden" }}
      className={type === "stops" ? "stop-class" : ""}
      onSearch={(val) => {
        setValue(val);
      }}
      placeholder={placeholder}
    />
  );
}
