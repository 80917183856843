import { Fleet } from "@/types/model.types";
import Image from "next/image";
import Link from "next/link";

type FleetCardProps = {
  fleet: Fleet[] | undefined;
  isSlider?: boolean;
};

export default function FleetNewCar({ fleet }: FleetCardProps) {
  // const primaryImage =
  // sfleet.images && sfleet.images.length > 0 ? sfleet.images[0] : null;
  return (
    <div className="container mt-5 ">
      <div className="  grid md:grid-cols-2  lg:grid-cols-4 gap-x-6 gap-y-4 p-2 mt-6">
        {fleet?.map((sfleet: Fleet) => (
          <div key={sfleet.id} className=" ">
            <Link
              className="text-[#f9af00] font-semibold text-[22px]"
              href={`/fleets/${sfleet.slug}`}
            >
              <Image
                width={450}
                height={450}
                src={`${process.env.NEXT_PUBLIC_DO_CDN}/${sfleet?.images[0]?.image}`}
                alt="car"
                className="rounded-[4px]"
              />
            </Link>
            <div className=" flex justify-between items-center mt-3">
              <h5 className="text-[#fff] text-[22px]">{sfleet.title}</h5>
              <span className="bg-[#f1a900] text-[#fff] px-2 rounded-[4px]">
                {sfleet.passenger_limit} seater
              </span>
            </div>

            <div className="flex  justify-between px-3 py-1 gap-2 mt-6 text-[#fff]">
              <div className="flex flex-col gap-1 items-center">
                <i className="fa fa-suitcase  seto-text seto-text"></i>
                <span>{sfleet.luggage_limit} </span>
              </div>
              <div>
                {sfleet.category && (
                  <div className="flex flex-col items-center gap-1">
                    <i className="fa fa-car  seto-text seto-text"></i>
                    <span className=" text-nowrap">
                      {" "}
                      {sfleet.category.title}
                    </span>
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-1 items-center">
                <Image
                  width={16}
                  height={16}
                  src="/images/icons/3w.svg"
                  className="img-fluid"
                  alt=""
                  style={{ marginRight: "10px", marginLeft: "-2px" }}
                />
                <span>{sfleet.heated_seats ? "Yes" : "No"} </span>
              </div>
            </div>

            <div className="flex  justify-between items-center mt-2 border-t pt-3">
              {/* {sfleet?.fleet_rate?.per_hour ? (
                <p className="text-[#fff]">
                  <span className="text-[22px] text-[#fff]">
                    ${sfleet?.fleet_rate?.per_hour}
                  </span>
                  / per hour
                </p>
              ) : (
                "Not Available"
              )} */}

              <div className="flex w-full items-center ">
                <Link
                  className="text-[#f9af00] font-semibold text-[22px]"
                  href={`/fleets/${sfleet.slug}`}
                >
                  View
                </Link>
              </div>
            </div>
          </div>
        ))}
        {/* single fleet element */}
      </div>
    </div>
  );
}
