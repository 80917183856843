export const FaqData = [
  {
    number: 1,
    question: "What is a pick-and-drop car service?",
    answer: "A pick-and-drop car service provides transportation to and from specific locations, offering convenience for daily commutes, airport transfers, or other travel needs."
  },



  {
    number: 2,
    question: "Are your drivers professional and verified?",
    answer: "Yes, all our drivers are thoroughly vetted, professionally trained, and verified to ensure safety and high-quality service for our customers."
  },
  {
    number: 3,
    question: "Do you offer services for special events or long-distance trips?",
    answer: "Yes, we offer customized services for special events and long-distance trips. Contact our customer service team to discuss your specific requirements and get a tailored quote."
  }, {
    number: 4,
    question: "How do I book a ride?",
    answer: "Booking a ride is easy. You can use our mobile app, website, or call our customer service team to schedule your ride at a convenient time."
  },
];
