import ServiceCard from "@/comp/ServiceCard";
import UserReviewCard from "@/comp/UserReviewCard";
import ContactUsSection from "@/comp/ContactUsSection";
import FleetCard from "@/comp/fleet/FleetCard";
import { GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import request from "@/utils/request.utils";
import { Category, Fleet } from "@/types/model.types";
import Head from "next/head";
import Slider from "react-slick";
import ReservationForm from "../comp/ReservationForm";
import { useEffect } from "react";
import {
  reservationInputChange,
  updateReservation,
} from "@/store/slices/reservation";
import { AppDispatch, RootState } from "@/store";
import { useDispatch } from "react-redux";
import FleetCardTwo from "@/comp/fleet/FleetCardTwo";
import Image from "next/image";
import { IoAirplaneOutline } from "react-icons/io5";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { FaFeatherPointed } from "react-icons/fa6";
import { FaBirthdayCake } from "react-icons/fa";
import { GiPartyPopper } from "react-icons/gi";
import { LuGift } from "react-icons/lu";
import FleetNewCar from "@/comp/fleet/FleetNewCard";
import Banner from "./Banner";
import Testomonials from "./Testomonial";
import ContactUs from "@/comp/ContactUs/ContactUs";
import Faq from "@/comp/Faq/Faq";

type FleetDetailProps = {
  fleets?: {
    count: number;
    rows: Fleet[];
  };
  categories?: {
    count: number;
    rows: Category[];
  };
};

const WorkStep = [
  {
    title: "Luxury vehicle",
    icons: <IoAirplaneOutline />,
    description:
      "Experience the epitome of excellence with our high-quality cars, meticulously designed for performance, luxury, and durability. Each vehicle is crafted to deliver unparalleled comfort, cutting-edge technology, and superior efficiency, ensuring a driving experience like no other. ",
  },
  {
    title: "Top-tier automobile",
    icons: <MdOutlineBusinessCenter />,
    description:
      "Experience the epitome of excellence with our high-quality cars, meticulously designed for performance, luxury, and durability. Each vehicle is crafted to deliver unparalleled comfort, cutting-edge technology, and superior efficiency, ensuring a driving experience like no other. ",
  },
  {
    title: "High-end car",
    icons: <FaFeatherPointed />,
    description:
      "Experience the epitome of excellence with our high-quality cars, meticulously designed for performance, luxury, and durability. Each vehicle is crafted to deliver unparalleled comfort, cutting-edge technology, and superior efficiency, ensuring a driving experience like no other. ",
  },
];
// services listedgit
const serviceTypes = [
  {
    title: "Airport Transfers",
    icons: <IoAirplaneOutline />,
    description:
      "Use airport shuttle service from Mt Everest Limo in CA, and you’ll never have to break a sweat … or worry about traffic, driving in bad weather, or the cost and hassle of airport parking.",
  },
  {
    title: "Business Limo Services",
    icons: <MdOutlineBusinessCenter />,
    description:
      "Mt Everest Limo  gives our Corporate and Executive clients thebest experience by providing a Corporate limo service in anupscale and timely manner. They will feel as though they are ontop of the world because of the classiness of our Mt Everest Limos.",
  },
  {
    title: "Point to Point",
    icons: <FaFeatherPointed />,
    description:
      "Where would you like to go, meeting or touring of CA City? Our professional chauffeurs can provide the luxury transportation that you deserve, with safe and reliable service.",
  },
  {
    title: "Birthdays",
    icons: <FaBirthdayCake />,
    description:
      "When a big birthday comes around, surprise your loved one witha stretch Mt Everest Limo to celebrate the special moment.Imagine how excited they’ll be when Mt Everest Limo pulls upin a limo bus with all their friends on board!",
  },
  {
    title: "Proms",
    icons: <LuGift />,
    description:
      "Kids and parents might have different priorities when it comes to prom limo rentals. Mt Everest Limo is your experienced, reliable partner who makes everyone happy in CA",
  },
  {
    title: "Night Party",
    icons: <GiPartyPopper />,
    description:
      "Night party limo service. For your night on the town you can rely on Mt Everest Limo to get you there in ultimate comfort, style and safety.",
  },
];
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
export default function Home({ fleets }: FleetDetailProps) {
  const filteredFleet: Fleet[] | undefined = fleets?.rows;

  const dispatch = useDispatch<AppDispatch>();
  <Head>
    <title>Mt Everest Limo</title>
    <link
      rel="stylesheet"
      type="text/css"
      // charset="UTF-8"
      href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
    />
    <link
      rel="stylesheet"
      type="text/css"
      href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
    />
  </Head>;
  return (
    <>
      <div className="no-bottom no-top " id="">
        <section
          className="hide-on-mobile video-height"
          id="section-hero"
          aria-label="section"
        >
          <video className="video2" autoPlay muted loop>
            <source src="video.mp4" />
          </video>
          {/* <div className="hero-items">
            <div className="container-fluid">
              <div className="row align-items-center justify-content-between">
                <div className="spacer-double sm-hide"></div>
                <div className="col hero-text">
                  <h4>
                    <span className="id-color">Plan your trip now</span>
                  </h4>
                  <div className="spacer-10"></div>
                  <h1 className="banner-main-title">
                    Discover unparalleled <br />
                    luxury and convenience
                  </h1>
                  <p className="lead">
                    We specialize in fleet bookings for airport transfers and
                    lavish journeys to elevate your travel experience.
                  </p>
                </div>
                <div className="col-lg-6">
                  <div className="de-flex flex-col justify-end">
                    <ReservationForm />
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div
            className=" w-max"
            style={{
              position: "absolute",
              top: "55%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="flex justify-between items-center gap-4">
              <div className="max-w-[450px]">
                <h1 className="text-[#f9af00] text-xl tracking-wide">
                  Plan your trip now
                </h1>
                <h2 className="text-[#fff]">
                  Discover unparalleled <br />
                  luxury and convenience
                </h2>
                <p className="text-[#ccc]">
                  We specialize in fleet bookings for airport transfers and
                  lavish journeys to elevate your travel experience.
                </p>
              </div>
              <div className="">
                <ReservationForm />
              </div>
            </div>
          </div>
        </section>
        <section
          id="section-cars"
          className=" car-container hide-on-desktop"
          style={{
            backgroundImage: "url(/images/background/sf-bridge.webp)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className=" hide-on-desktop">
            <div className="row"></div>
            <h3 className="text-warning text-center">MT EVEREST LIMOUSINE</h3>
            <h4 className="text-white text-center mt-10 mb10">
              Plan your trip now...
            </h4>
            <ReservationForm />
          </div>
        </section>
        <section className="bg-[#000] hide-on-mobile">
          <div className="container pt-10">
            <h2 className="text-[#fff] tracking-wide">How we work?</h2>
            <div className=" grid grid-cols-3 gap-x-10  p-2 mt-6">
              {WorkStep.map((item, index) => {
                return (
                  <div key={index} className="">
                    <div className="flex items-center gap-2">
                      <p className=" font-semibold text-[#ffff] bg-[#f9af00] text-[46px]  rounded-[4px] p-3 w-fit">
                        {index + 1}
                      </p>
                      <div className=" border-dashed border-t  w-full text-[#fff]"></div>
                    </div>
                    <h4
                      className="text-[#fff] lg:text-[28px]"
                      style={{ marginTop: "1rem" }}
                    >
                      {item.title}
                    </h4>
                    <p className="text-ellipsis line-clamp-4 text-[#d8d8d8]">
                      {item.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section className="bg-[#000]">
          <div className="container py-2">
            <div className="text-center flex justify-center flex-col items-center">
              <h2 className="text-[#fff]">Our Fleet</h2>
              <p className="max-w-[500px]">
                Explore our range of vehicles and enjoy a first-class ride with
                Mt Everest Limo.
              </p>
            </div>
            <FleetNewCar fleet={filteredFleet && filteredFleet} />
          </div>
        </section>

        <Banner />

        <section
          className="text-light jarallax services-section"
          aria-label="section"
          style={{
            backgroundColor: "#000",
          }}
        >
          <div className="container mx-auto" style={{ padding: "56px 0 44px" }}>
            <div
              className="services-title text-center"
              style={{ marginBottom: "64px" }}
            >
              <h1 className="mb-0">Our Services</h1>
            </div>

            <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-4 p-2">
              {serviceTypes.map((item, index) => {
                return (
                  <div key={index} className="">
                    <div style={{ color: "#f9af00", fontSize: "46px" }}>
                      {item.icons}
                    </div>
                    <h4 style={{ marginTop: "1rem" }}>{item.title}</h4>
                    <p className="text-ellipsis line-clamp-4">
                      {item.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        {false && (
          <section id="section-cars">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 offset-lg-3 text-center">
                  <h2>Our Ratings</h2>
                  <p>
                    Sit labore et officia eiusmod tempor officia eiusmod dolor
                    exercitation nulla dolore ut id velit et ut anim.
                  </p>
                  <div className="spacer-20"></div>
                </div>

                <div>
                  <div className="row">
                    <UserReviewCard />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        <section style={{ backgroundColor: "#000" }}>
          <div
            className="container"
            style={{
              backgroundImage: "url('images/background/bg-car.png')",
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "50vh",
            }}
          ></div>
        </section>
        <section className="bg-[#000]">
          <Testomonials />
        </section>

        <section className="bg-[#000]">
          <Faq />
        </section>

        <ContactUsSection />
      </div>
    </>
  );
}

export async function getServerSideProps(
  context: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<any>> {
  const fleets = await request.get(
    `/fleets?withCategory=true&withRates=true&withImages=true&catId=${
      context.query.cat ? parseInt(context.query.cat as string, 10) : ""
    }`,
  );
  const categories = await request.get("/categories?onlyParent=true");
  return {
    props: {
      fleets: fleets.status === 200 ? fleets.data : [],
      categories: categories.status === 200 ? categories.data : [],
    },
  };
}

// export async function getGoogleRating(
// ): Promise<GetServerSidePropsResult<any>> {
//   const rating = await request.get('mybusiness.googleapis.com/v4/accounts/{AIzaSyCfd3TwXnERJGJAILkjhDjqRxccQndLbJI}/locations/{Od5hYAR7jo0nxcsOEw}/reviews');
//   console.log('rating', rating)
//   return {
//     props: {
//       rating: rating,
//     },
//   };
// }
