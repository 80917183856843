import Image from "next/image";
import React from "react";
import Marquee from "react-fast-marquee";

const testoData = [
  {
    title: "Outstanding Car Rental Experience",
    description:
      "The car rental service exceeded our expectations with its seamless booking process and well-maintained vehicles. Truly a reliable and efficient choice for travel.",
    name: "Jane Doe",
    position: "Chief Technology Officer",
    company_img: "company1_logo.png",
    profile_img: "/images/people/1.jpg",
  },
  {
    title: "Exceptional Customer Support",
    description:
      "Their customer service was exceptional! From booking to drop-off, the experience was smooth and hassle-free. Highly recommended for convenience and affordability.",
    name: "John Smith",
    position: "Head of Customer Experience",
    company_img: "company2_logo.png",
    profile_img: "/images/people/2.jpg",
  },
  {
    title: "Flexible and Affordable Rentals",
    description:
      "The flexible rental options allowed us to choose vehicles that perfectly fit our needs. Their competitive pricing made the experience even better.",
    name: "Emily Clark",
    position: "Director of Business Strategy",
    company_img: "company3_logo.png",
    profile_img: "/images/people/3.jpg",
  },
  {
    title: "Top-Notch Vehicle Quality",
    description:
      "The vehicles were in excellent condition, ensuring a safe and comfortable journey. Their commitment to quality is unmatched in the industry.",
    name: "Michael Johnson",
    position: "Operations Manager",
    company_img: "company4_logo.png",
    profile_img: "/images/people/4.jpg",
  },
  {
    title: "Hassle-Free Long-Term Rentals",
    description:
      "Their long-term rental options provided us with the flexibility and convenience we needed for extended trips. A fantastic service that I highly recommend!",
    name: "Sarah Lee",
    position: "Marketing Director",
    company_img: "company5_logo.png",
    profile_img: "/images/people/3.jpg",
  },
];

const Testomonials = () => {
  return (
    <div className="container  text-center">
      <div className="services-title text-center ">
        <h1 className="text-[#fff]">Our Clients Reviews</h1>
      </div>

      <Marquee pauseOnHover className="grid  pt-10">
        {testoData.map((item, index) => {
          return (
            <div
              key={index}
              className="grid mx-3  border-[#f9af00] min-h-[250px] rounded-[6px] max-w-[400px] p-4"
            >
              <div className="flex flex-row mb-4 text-[#f9af00] w-full justify-center items-center gap-2">
                <i className="fa fa-star" aria-hidden="true" />
                <i className="fa fa-star" aria-hidden="true" />
                <i className="fa fa-star" aria-hidden="true" />
                <i className="fa fa-star" aria-hidden="true" />
                <i className="fa fa-star" aria-hidden="true" />
              </div>
              <div className="">
                <p className="text-[24px] text-[#fff]">{item.title}</p>
                <p className="mt-2 text-[16px] text-[#858585]">
                  {item.description}
                </p>
              </div>
              <div className="flex justify-center items-center gap-2 mt-4">
                <div>
                  <Image
                    priority={true}
                    className="flex justify-center items-center rounded-full"
                    width={40}
                    height={40}
                    src={item.profile_img}
                    alt="profile"
                  />
                </div>

                <div className="flex flex-col ">
                  <p className="text-[16px] font-semibold text-[#f9af00]">
                    {item.name}
                  </p>
                  {/* <p className="text-[14px]  font-semibold text-[#f9af00]">
                    {item.position}
                  </p> */}
                </div>
              </div>
            </div>
          );
        })}
      </Marquee>
    </div>
  );
};

export default Testomonials;
