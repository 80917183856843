import axios from "axios";
import { time } from "console";
import { format } from "date-fns";

const API_URL = "/localtime"; // Replace with your actual API endpoint

interface TimezoneData {
  status: string;
  timeZoneId: string;
  timezoe: string;
  dstOffset: number;
  rawOffset: number;
}

export const getTimezoneByLatLng = async (
  lat: number,
  lng: number,
): Promise<TimezoneData> => {
  try {
    const response = await axios.post(API_URL, {
      lat, // Sending latitude in the request body
      lng, // Sending longitude in the request body
    });

    if (response.status === 200) {
      return response.data; // Return the timezone data from the response
    } else {
      throw new Error("Failed to fetch timezone data");
    }
  } catch (error) {
    console.error("Error fetching timezone data:", error);
    throw new Error("Failed to fetch timezone data");
  }
};

export const getTimeInTimezone = (timezone: string) => {
  return new Date().toLocaleString("en-US", { timeZone: timezone });
};
