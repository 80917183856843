import FaqContent from "./Content/FaqContent";
import Image from "next/image";

const Faq = () => {
  return (
    <div
      id="faqs"
      className="main_container inside_sidespace flex flex-col justify-center items-center w-full"
    >
      <div>
        <div className="mt-[50px] flex flex-col justify-center items-center w-full">
          <span className="text-[#f9af00]  text-[18px]  font-semibold ">
            Frequently Asked Questions
          </span>
        </div>

        <div className="max-w-[586px] mt-[8px]">
          <p className="text-[#ffffff] text-wrap inside_sidespace font-semibold text-[32px] lg:tracking-[-1px] leading-[120%] text-center">
          Get insights into common concerns and questions about our services
          </p>
        </div>
      </div>

      <div className="relative">
        <FaqContent />
      </div>
    </div>
  );
};

export default Faq;
