import PlacesAutoComplete from "@/comp/PlacesAutoComplete";
import { AppDispatch, RootState } from "@/store";
import { fetchDefaultLocation } from "@/store/slices/default-location";
import { fetchFleet } from "@/store/slices/fleet";
import {
  reservationInputChange,
  returnInputChange,
} from "@/store/slices/reservation";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { FaGripVertical } from "react-icons/fa";
import { DefaultLocation } from "@/types/model.types";
import { useJsApiLoader } from "@react-google-maps/api";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  Segmented,
  Select,
  Tabs,
  TimePicker,
} from "antd";
import moment, { now } from "moment";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CaretRightOutlined, CloseOutlined } from "@ant-design/icons";
import { TotalStopType } from "@/types/index.types";
import { useRouter } from "next/navigation";
import { getTimeInTimezone, getTimezoneByLatLng } from "@/utils/timezone";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
type Align = "distance" | "hourly" | "airport-transfer";

const ReservationForm = () => {
  const router = useRouter();

  const dispatch = useDispatch<AppDispatch>();
  const { model } = useSelector((state: RootState) => state.reservation);
  const { data: defaultLocations } = useSelector(
    (state: RootState) => state.defaultLocation,
  );
  const [totalStops, setTotalStops] = useState<TotalStopType[]>([]);
  const [totalReturnStops, setReturnTotalStops] = useState<TotalStopType[]>([]);

  const [selectedDate, setSelectedDate] = useState(null);

  const [selectedTime, setSelectedTime] = useState(null);
  const [returnDate, setReturnDate] = useState(null);
  const [returnTime, setReturnTime] = useState(null);
  const [activeButton, setActiveButton] = useState(0);
  const [alignValue, setAlignValue] = React.useState<Align>("distance");

  const buttons = ["Distance", "Hourly", "Airport Transfer"];

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API as string,
    libraries: ["places"],
  });

  const [selectedDateTimeTry, setSelectedDateTimeTry] =
    React.useState<dayjs.Dayjs | null>(dayjs());

  const handleDateTimeChangeTry = (newDateTime: dayjs.Dayjs | null) => {
    setSelectedDateTimeTry(newDateTime);
    if (newDateTime) {
      const formattedDateTime = newDateTime.format("YYYY-MM-DD HH:mm"); // Convert to string format
      setSelectedDateTimeTry(newDateTime);
      dispatch(
        reservationInputChange({ key: "pickup_at", value: formattedDateTime }),
      );
    }
  };
  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = [...totalStops];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setTotalStops(items);
  };
  const handleDragEndReturn = (result: any) => {
    if (!result.destination) return;

    const items = [...totalReturnStops];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setReturnTotalStops(items);
  };
  const handleGetTimezone = async (
    lat: string,
    lng: string,
  ): Promise<string> => {
    if (!lat || !lng) return "";

    try {
      const latitude = parseFloat(lat);
      const longitude = parseFloat(lng);

      if (isNaN(latitude) || isNaN(longitude)) return "";

      const data = await getTimezoneByLatLng(latitude, longitude);

      return data.timezoe || ""; // Ensure it returns a string
    } catch (error) {
      return ""; // Handle errors by returning an empty string
    }
  };

  const checkTimeCorrect = async () => {
    if (
      totalStops.length > 0 &&
      totalStops.some((item) => item.description === "")
    ) {
      error(
        "Stop point is empty. Please enter an address or remove the stop point to proceed.",
        "Error",
      );

      return;
    }
    if (
      model?.transfer_type === "return" &&
      totalReturnStops.length > 0 &&
      totalReturnStops.some((item) => item.description === "")
    ) {
      error(
        "Return Stop point is empty. Please enter an address or remove the stop point to proceed.",
        "Error",
      );

      return;
    }
    const timezone = await handleGetTimezone(
      model.pickup_lat,
      model.pickup_lng,
    );
    const returntimezone = await handleGetTimezone(
      model.return_pickup_lat,
      model.return_pickup_lng,
    );

    if (timezone) {
      //If timezone is selected check if the time is after current time of location.
      const pickupDate = new Date(model?.pickup_at);
      const currentDateOfPickupLocation = new Date(getTimeInTimezone(timezone));

      if (currentDateOfPickupLocation > pickupDate) {
        // openPopup();
        error(
          "Selected date is before current time in given location",
          "Check Date & Time",
        );

        return;
      }
    }

    if (returntimezone) {
      // If return time zone is selected check if it is later than the pickup date
      const pickupDate = new Date(model?.pickup_at);
      const returnpickupDate = new Date(model?.return_pickup_at);

      if (model?.transfer_type === "return" && pickupDate > returnpickupDate) {
        error("Return date is before pickup date", "Check Date & Time");
        return;
      }
    }

    if (model.service_type === "distance") {
      dispatch(
        reservationInputChange({
          key: "stops",
          value: totalStops,
        }),
      );
      dispatch(
        reservationInputChange({
          key: "returnstops",
          value: totalReturnStops,
        }),
      );
    }
    if (
      model?.service_type === "distance" ||
      model?.service_type === "airport-transfer"
    ) {
      dispatch(
        reservationInputChange({
          key: "extra_hours",
          value: 0,
        }),
      );
    }
    if (model.service_type === "hourly") {
      dispatch(
        reservationInputChange({
          key: "transfer_type",
          value: "one-way",
        }),
      );
    }
    router.push("/reservations");

    //need to check if pickup time is before current time of location
    //need to check if return pickup time is before pickup time and after current time of location
    //Get thetime of the given timezone
  };
  //Default the value
  useEffect(() => {
    dispatch(
      reservationInputChange({
        key: " ",
        value: "",
      }),
    );
  }, []);

  useEffect(() => {
    if (activeButton === 0) {
      dispatch(
        reservationInputChange({
          key: "service_type",
          value: "distance",
        }),
      );
    }
    if (activeButton === 1) {
      dispatch(
        reservationInputChange({
          key: "service_type",
          value: "hourly",
        }),
      );
      dispatch(
        reservationInputChange({
          key: "transfer_type",
          value: "one-way",
        }),
      );
      dispatch(
        reservationInputChange({
          key: "dropoff_lat",
          value: null,
        }),
      );
      dispatch(
        reservationInputChange({
          key: "dropoff_lng",
          value: null,
        }),
      );
      dispatch(
        reservationInputChange({
          key: "dropoff_location_desc",
          value: null,
        }),
      );
      dispatch(
        reservationInputChange({
          key: "dropoff_location_key",
          value: null,
        }),
      );
      dispatch(
        reservationInputChange({
          key: "map_distance_meter",
          value: null,
        }),
      );
      dispatch(
        reservationInputChange({
          key: "map_distance_meter",
          value: null,
        }),
      );
    }
    if (activeButton === 2) {
      dispatch(
        reservationInputChange({
          key: "service_type",
          value: "airport-transfer",
        }),
      );
    }
  }, [activeButton]);

  useEffect(() => {
    dispatch(reservationInputChange({ key: "pickup_at", value: "" }));
    dispatch(
      reservationInputChange({ key: "transfer_type", value: "one-way" }),
    );
    dispatch(reservationInputChange({ key: "extra_hours", value: "1" }));
    dispatch(reservationInputChange({ key: "child_seats", value: "0" }));
    dispatch(reservationInputChange({ key: "booster_seat", value: "0" }));

    dispatch(
      reservationInputChange({
        key: "pickup_location_desc",
        value: null,
      }),
    );
    dispatch(
      reservationInputChange({
        key: "pickup_location_key",
        value: null,
      }),
    );
    dispatch(
      reservationInputChange({
        key: "pickup_lat",
        value: null,
      }),
    );

    dispatch(
      reservationInputChange({
        key: "pickup_lng",
        value: null,
      }),
    );
    dispatch(
      reservationInputChange({
        key: "dropoff_location_desc",
        value: null,
      }),
    );
    dispatch(
      reservationInputChange({
        key: "dropoff_location_key",
        value: null,
      }),
    );
    dispatch(
      reservationInputChange({
        key: "dropoff_lat",
        value: null,
      }),
    );

    dispatch(
      reservationInputChange({
        key: "dropoff_lng",
        value: null,
      }),
    );
    dispatch(
      reservationInputChange({
        key: "service_type",
        value: "distance",
      }),
    );
    dispatch(
      reservationInputChange({
        key: "passenger",
        value: 1,
      }),
    );
    dispatch(
      fetchFleet({ withImages: false, withRates: true, withCategory: true }),
    );
    dispatch(reservationInputChange({ key: "luggage", value: 0 }));
    dispatch(reservationInputChange({ key: "hand_luggage", value: 0 }));

    // For return type if return pickup addres is differnt
    dispatch(
      reservationInputChange({
        key: "return_pickup_location_desc",
        value: model.return_pickup_location_desc,
      }),
    );
    dispatch(
      reservationInputChange({
        key: "return_pickup_location_key",
        value: model.return_pickup_location_key,
      }),
    );
    dispatch(reservationInputChange({ key: "return_pickup_at", value: null }));
    dispatch(reservationInputChange({ key: "return_pickup_lat", value: null }));
    dispatch(reservationInputChange({ key: "return_pickup_lng", value: null }));
    dispatch(
      reservationInputChange({ key: "return_dropoff_lat", value: null }),
    );
    dispatch(
      reservationInputChange({ key: "return_dropoff_lng", value: null }),
    );

    dispatch(
      reservationInputChange({
        key: "return_pickup_location_key",
        value: null,
      }),
    );
    dispatch(
      reservationInputChange({
        key: "return_dropoff_location_key",
        value: null,
      }),
    );
    dispatch(
      reservationInputChange({ key: "return_map_distance_plain", value: null }),
    );
    dispatch(
      reservationInputChange({ key: "return_map_distance_meter", value: null }),
    );
    dispatch(
      reservationInputChange({ key: "return_map_duration_plain", value: null }),
    );
    dispatch(
      reservationInputChange({
        key: "return_map_duration_seconds",
        value: null,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (
      model.service_type === "airport-transfer" &&
      defaultLocations.length === 0
    ) {
      dispatch(
        fetchDefaultLocation<{ service_type: String }>({
          service_type: model.service_type,
        }),
      );
    }
  }, [model?.service_type, dispatch, defaultLocations]);

  const onTabChange = (key: string) => {
    dispatch(
      reservationInputChange({
        key: "service_type",
        value: key,
      }),
    );
  };

  const handleDateChange = (_: any, dateString: any) => {
    setSelectedDate(dateString);
    if (selectedTime) {
      const combinedDateTime = moment(
        `${dateString} ${selectedTime}`,
        "YYYY-MM-DD HH:mm A",
      ).format("YYYY-MM-DD HH:mm");
      dispatch(
        reservationInputChange({
          key: "pickup_at",
          value: combinedDateTime,
        }),
      );
    }
  };
  const handleReturnDateChange = (_: any, dateString: any) => {
    setReturnDate(dateString);
    if (returnTime) {
      const combinedDateTime = moment(
        `${dateString} ${returnTime}`,
        "YYYY-MM-DD HH:mm A",
      ).format("YYYY-MM-DD HH:mm");
      dispatch(
        reservationInputChange({
          key: "return_pickup_at",
          value: combinedDateTime,
        }),
      );
    }
  };

  const handleTimeChange = (_: any, timeString: any) => {
    setSelectedTime(timeString);
    if (selectedDate) {
      const combinedDateTime = moment(
        `${selectedDate} ${timeString}`,
        "YYYY-MM-DD HH:mm A",
      ).format("YYYY-MM-DD HH:mm");
      dispatch(
        reservationInputChange({
          key: "pickup_at",
          value: combinedDateTime,
        }),
      );
    }
  };
  const handleReturnTimeChange = (_: any, timeString: any) => {
    setReturnTime(timeString);
    if (returnDate) {
      const combinedDateTime = moment(
        `${returnDate} ${timeString}`,
        "YYYY-MM-DD HH:mm A",
      ).format("YYYY-MM-DD HH:mm");
      dispatch(
        returnInputChange({
          key: "return_pickup_at",
          value: combinedDateTime,
        }),
      );
    }
  };

  return (
    <div>
      <Form
        className="hide-on-mobile"
        layout="vertical"
        style={{
          borderRadius: "10px",
          maxWidth: "450px",
          padding: "0",
          marginTop: "auto",
        }}
      >
        <Tabs
          size="large"
          onChange={onTabChange}
          items={[
            {
              key: "distance",
              label: (
                <span>
                  <i className="fa fa-location-dot me-2"></i> Distance
                </span>
              ),
            },
            {
              key: "hourly",
              label: (
                <span>
                  <i className="fa fa-clock me-2"></i> Hourly
                </span>
              ),
            },
            {
              key: "airport-transfer",
              label: (
                <span>
                  <i className="fa fa-plane me-2"></i> Airport Transfer
                </span>
              ),
            },
          ]}
        />
        <div
          style={{
            width: "100%",
            padding: "16px",
            backgroundColor: "#fff",
            borderRadius: "0 0 10px 10px",
          }}
        >
          <div className="row g-2">
            <div className="col-md-6">
              <Form.Item label="Pickup Date" tooltip="Select a date">
                <DatePicker
                  format="YYYY-MM-DD"
                  size="large"
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current < moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  // format='YYYY-MM-DD HH:mm A'

                  onChange={handleDateChange}
                  onOk={() => {}}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item label="Pickup Time" tooltip="Select a time">
                <TimePicker
                  size="large"
                  format="h:mm A"
                  onChange={handleTimeChange}
                  use12Hours
                  onOk={() => {}}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </div>
          </div>
          <div className="d-flex w-100 gap-2">
            <div className={"w-100"}>
              <Form.Item
                label="Pickup Location"
                tooltip="Please provide the specific location where you would like us to arrange your pickup."
              >
                {isLoaded && (
                  <>
                    <PlacesAutoComplete
                      value={
                        model.pickup_location_key
                          ? {
                              label: model.pickup_location_desc,
                              value: model.pickup_location_key,
                            }
                          : null
                      }
                      size="large"
                      width={
                        model?.service_type === "distance" ? "406px" : "406px"
                      }
                      defaultLocations={defaultLocations
                        .filter(
                          (loc: DefaultLocation) => loc.list_type === "pickup",
                        )
                        .map((loc: DefaultLocation) => ({
                          label: loc.description,
                          value: loc.place_id,
                        }))}
                      type="pickup"
                      onSelected={(val, selectedData: any) => {
                        dispatch(
                          reservationInputChange({
                            key: "pickup_location_desc",
                            value: selectedData.description,
                          }),
                        );
                        dispatch(
                          reservationInputChange({
                            key: "pickup_location_key",
                            value: selectedData.place_id,
                          }),
                        );
                        dispatch(
                          reservationInputChange({
                            key: "pickup_lat",
                            value: val.lat,
                          }),
                        );

                        dispatch(
                          reservationInputChange({
                            key: "pickup_lng",
                            value: val.lng,
                          }),
                        );
                      }}
                      placeholder="Your pickup location"
                    />

                    {/* {model?.service_type === 'distance' && (
                    <small>
                      Click the <code>`Add Stop`</code> button to
                      add multiple stops or way points
                    </small>
                  )} */}
                  </>
                )}
              </Form.Item>
            </div>
            {/* {model?.service_type === "distance" && (
              <div className="mt-4" style={{ paddingLeft: 0, marginLeft: 0 }}>
                <Button
                  type="text"
                  size="large"
                  block
                  style={{
                    color: "rgb(249, 175, 0)",
                    background: "rgba(0, 0, 0, 0.02)",
                  }}
                  onClick={() => {
                    setTotalStops([
                      ...totalStops,
                      {
                        place_id: "",
                        description: "",
                        latlng: { lat: 0, lng: 0 },
                        waiting_time: null,
                      },
                    ]);
                  }}
                  title="Add additional stops"
                >
                  <i className="fa fa-plus" />
                </Button>
              </div>
            )} */}
          </div>
          <div>
            {model?.service_type !== "hourly" && (
              <div>
                {/* sample drag  */}
                <div className="  mx-auto ">
                  <DragDropContext onDragEnd={handleDragEndReturn}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className=" p-2 rounded-lg w-full "
                        >
                          {totalReturnStops.map((totalStop, index) => (
                            <Draggable
                              key={index}
                              draggableId={`input${index}`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="flex items-center mb-2 bg-white p-2 rounded-lg shadow"
                                >
                                  <FaGripVertical className="mr-2 cursor-grab" />
                                  <Button
                                    type="link"
                                    size="large"
                                    danger
                                    onClick={() => {
                                      const stops = [...totalReturnStops];

                                      // console.log(stops);
                                      stops.splice(index, 1);
                                      dispatch(
                                        reservationInputChange({
                                          key: "stops",
                                          value: [...stops],
                                        }),
                                      );
                                      setTotalStops([...stops]);
                                    }}
                                  >
                                    <div>
                                      <CloseOutlined />
                                    </div>
                                  </Button>
                                  <PlacesAutoComplete
                                    size="large"
                                    placeholder={"Add a stop point"}
                                    value={
                                      totalStop.place_id
                                        ? {
                                            label: totalStop.description,
                                            value: totalStop.place_id,
                                          }
                                        : null
                                    }
                                    // inputid={`stoploc${index}`}
                                    type="stops"
                                    onSelected={(val, selectedData: any) => {
                                      const stops = totalReturnStops.map(
                                        (ts, j) => {
                                          if (j === index) {
                                            return {
                                              place_id: selectedData.place_id,
                                              description:
                                                selectedData.description,
                                              latlng: {
                                                lat: val.lat,
                                                lng: val.lng,
                                              },
                                              waiting_time: ts.waiting_time,
                                            };
                                          }
                                          return ts;
                                        },
                                      );
                                      stops[index] = {
                                        place_id: selectedData.place_id,
                                        description: selectedData.description,
                                        latlng: {
                                          lat: val.lat,
                                          lng: val.lng,
                                        },
                                        waiting_time: null,
                                      };
                                      setReturnTotalStops(stops);
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                {/* sample drag end */}
              </div>
            )}
          </div>
          {model?.service_type !== "hourly" && (
            <Form.Item
              label="Drop-off Location"
              tooltip="Select the destination for drop-off."
            >
              {isLoaded && (
                <PlacesAutoComplete
                  size="large"
                  width="406px"
                  value={
                    model.dropoff_location_key
                      ? {
                          label: model.dropoff_location_desc,
                          value: model.dropoff_location_key,
                        }
                      : null
                  }
                  defaultLocations={defaultLocations
                    .filter(
                      (loc: DefaultLocation) => loc.list_type === "dropoff",
                    )
                    .map((loc: DefaultLocation) => ({
                      label: loc.description,
                      value: loc.place_id,
                    }))}
                  type="dropoff"
                  onSelected={(val, selectedData: any) => {
                    dispatch(
                      reservationInputChange({
                        key: "dropoff_location_desc",
                        value: selectedData.description,
                      }),
                    );
                    dispatch(
                      reservationInputChange({
                        key: "dropoff_location_key",
                        value: selectedData.place_id,
                      }),
                    );
                    dispatch(
                      reservationInputChange({
                        key: "dropoff_lat",
                        value: val.lat,
                      }),
                    );

                    dispatch(
                      reservationInputChange({
                        key: "dropoff_lng",
                        value: val.lng,
                      }),
                    );
                  }}
                  placeholder="Your drop-off location"
                />
              )}
            </Form.Item>
          )}
          <div className="row g-2 mb-2">
            {model.service_type !== "hourly" && (
              <div className="col">
                <Form.Item
                  label="Transfer Type"
                  tooltip="Please indicate whether you prefer a one-way transfer or if you plan to return."
                >
                  <Select
                    size="large"
                    defaultValue={"one-way"}
                    onChange={(val) => {
                      dispatch(
                        reservationInputChange({
                          key: "transfer_type",
                          value: val,
                        }),
                      );
                    }}
                    options={[
                      { label: "One Way", value: "one-way" },
                      { label: "Return", value: "return" },
                    ]}
                  />
                </Form.Item>
              </div>
            )}
            {model?.service_type === "hourly" && (
              // (model.transfer_type === "return") disabled additional hour for return type
              <div className="col">
                <Form.Item
                  label={
                    model.service_type === "hourly"
                      ? "Total Hours"
                      : "Extra Hours"
                  }
                  tooltip="Choose additional hours according to your requirements; the selected duration will impact the total price."
                >
                  <Select
                    size="large"
                    defaultValue={4}
                    onChange={(val) => {
                      dispatch(
                        reservationInputChange({
                          key: "extra_hours",
                          value: val,
                        }),
                      );
                    }}
                    options={Array.from({ length: 12 }, (_, index) => ({
                      label: index + 4,
                      value: index + 4,
                    }))}
                  />
                </Form.Item>
              </div>
            )}

            {/* if travel is return type  and not hourly  */}

            <div className="col">
              <Form.Item
                label="Passengers"
                name="passenger"
                tooltip="Total number of passenger"
              >
                <Input
                  size="large"
                  placeholder="1"
                  type="number"
                  defaultValue="1"
                  maxLength={20}
                  name="passenger"
                  min={1}
                  max={20}
                  onChange={(val) => {
                    dispatch(
                      reservationInputChange({
                        key: "passenger",
                        value: val.target.value,
                      }),
                    );
                  }}
                />
              </Form.Item>
            </div>
          </div>
          <Button
            disabled={
              !model?.service_type ||
              !model?.pickup_at ||
              !model?.pickup_location_desc ||
              model.passenger < 1 ||
              model.passenger > 20 ||
              (model?.service_type === "distance" &&
                !model?.dropoff_location_desc) ||
              (model?.service_type === "airport-transfer" &&
                !model?.dropoff_location_desc)
            }
            onClick={() => {
              checkTimeCorrect();
            }}
            block
            size="large"
            type="primary"
          >
            Search &nbsp; <CaretRightOutlined className="next-icon-svg" />
          </Button>
        </div>
      </Form>

      {/* arko form */}
      <div className="col  hide-on-desktop ml10 mr10">
        {/* Providing form to change if needed */}
        <div className="meroservicecard">
          <div
            className="service-menu"
            style={{
              position: "relative",
              padding: " 0px 10px",
              display: "inline-block",
            }}
          >
            {/* Sliding Background */}
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: `${100 / buttons.length}%`,
                backgroundColor: "#ffffff",
                borderRadius: "10px",

                transition: "transform 0.3s ease",
                transform: `translateX(${activeButton * 100}%)`,
              }}
            ></div>

            {/* Buttons */}
            <div style={{ display: "flex", position: "relative" }}>
              {buttons.map((button, index) => (
                <button
                  key={index}
                  onClick={() => setActiveButton(index)}
                  style={{
                    flex: 1,
                    padding: "5px 10px",
                    border: "none",
                    background: "transparent",
                    borderRadius: "10px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: activeButton === index ? "black" : "#ffffff",
                    cursor: "pointer",
                    position: "relative",
                    zIndex: 1,
                  }}
                >
                  {button}
                </button>
              ))}
            </div>
          </div>
        </div>
        <Card className="cardko mb20">
          <h3>Trip Details</h3>
          <div className="row g-2">
            {/* use of material design date and time picker */}
            {/* <Form.Item label="Pickup Date" tooltip="Select a date">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  label="Select Date & Time"
                  value={
                    model?.pickup_at ? dayjs(model?.pickup_at) : dayjs(now())
                  }
                  onChange={handleDateTimeChangeTry}
                  slotProps={{
                    textField: { fullWidth: true },
                  }}
                />
              </LocalizationProvider>
            </Form.Item> */}
            <div className="col-md-6">
              <Form.Item label="Pickup Date" tooltip="Select a date">
                <DatePicker
                  size="large"
                  // defaultValue={model.pickup_at}
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current < moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  // format='YYYY-MM-DD HH:mm A'
                  format="YYYY-MM-DD"
                  onChange={handleDateChange}
                  onOk={() => {}}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item label="Pickup Time" tooltip="Select a time">
                <TimePicker
                  size="large"
                  format="hh:mm A"
                  onChange={handleTimeChange}
                  use12Hours
                  onOk={() => {}}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </div>
          </div>

          <div className="d-flex w-100 gap-2">
            <div className={"w-100"}>
              <div className="d-flex w-100">
                <Form.Item
                  label="Pickup Location"
                  tooltip="Please provide the specific location where you would like us to arrange your pickup."
                ></Form.Item>

                <div className="w-100 overflow-hidden">
                  {" "}
                  <Form.Item>
                    {isLoaded && (
                      <>
                        <div className="w-100">
                          <PlacesAutoComplete
                            value={
                              model.pickup_location_key
                                ? {
                                    label: model.pickup_location_desc,
                                    value: model.pickup_location_key,
                                  }
                                : null
                            }
                            size="large"
                            width={
                              model?.service_type !== "hourly" ? "100%" : "100%"
                            }
                            defaultLocations={defaultLocations
                              .filter(
                                (loc: DefaultLocation) =>
                                  loc.list_type === "pickup",
                              )
                              .map((loc: DefaultLocation) => ({
                                label: loc.description,
                                value: loc.place_id,
                              }))}
                            type="pickup"
                            onSelected={(val, selectedData: any) => {
                              dispatch(
                                reservationInputChange({
                                  key: "pickup_location_desc",
                                  value: selectedData.description,
                                }),
                              );
                              dispatch(
                                reservationInputChange({
                                  key: "pickup_location_key",
                                  value: selectedData.place_id,
                                }),
                              );
                              dispatch(
                                reservationInputChange({
                                  key: "pickup_lat",
                                  value: val.lat,
                                }),
                              );

                              dispatch(
                                reservationInputChange({
                                  key: "pickup_lng",
                                  value: val.lng,
                                }),
                              );
                            }}
                            placeholder="Your pickup location"
                          />
                        </div>

                        {/* {model?.service_type === 'distance' && (
                                <small>
                                  Click the <code>`Add Stop`</code> button to
                                  add multiple stops or way points
                                </small>
                              )} */}
                      </>
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          {model?.service_type !== "hourly" && (
            <div
              className="d-flex justify-content-end"
              // className="add-stop-btn"
              style={{
                paddingLeft: 0,
                marginLeft: 0,
                marginBottom: 0,
                marginTop: -10,
              }}
            >
              <Button
                type="text"
                size="large"
                block
                style={{
                  // color: "rgba(255, 255, 255, 0.02)",
                  color: "rgb(249, 175, 0)",
                  background: "rgb(249, 255, 255,0.2)",
                  // border: "2px solid rgb(249, 175, 0)",
                }}
                onClick={() => {
                  setTotalStops([
                    ...totalStops,
                    {
                      place_id: "",
                      description: "",
                      latlng: { lat: 0, lng: 0 },
                      waiting_time: null,
                    },
                  ]);
                }}
                title="Add additional stops"
              >
                <i className="fa fa-plus" />
                &nbsp; Add Stops
              </Button>
            </div>
          )}
          <div>
            {model?.service_type !== "hourly" && (
              <div>
                {/* sample drag  */}
                <div className="  mx-auto ">
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className=" p-2 rounded-lg w-full"
                        >
                          {totalStops.map((totalStop, index) => (
                            <Draggable
                              key={index}
                              draggableId={`input${index}`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="flex items-center mb-2 bg-white p-2 rounded-lg shadow"
                                >
                                  <FaGripVertical className="mr-2 cursor-grab" />
                                  <Button
                                    type="link"
                                    size="large"
                                    danger
                                    onClick={() => {
                                      const stops = [...totalStops];

                                      console.log(stops);
                                      stops.splice(index, 1);
                                      dispatch(
                                        reservationInputChange({
                                          key: "stops",
                                          value: [...stops],
                                        }),
                                      );
                                      setTotalStops([...stops]);
                                    }}
                                  >
                                    <div>
                                      <CloseOutlined />
                                    </div>
                                  </Button>
                                  <div className=" text-ellipsis overflow-hidden w-full">
                                    <PlacesAutoComplete
                                      size="large"
                                      placeholder={"Add a stop point"}
                                      value={
                                        totalStop.place_id
                                          ? {
                                              label: totalStop.description,
                                              value: totalStop.place_id,
                                            }
                                          : null
                                      }
                                      // inputid={`stoploc${index}`}
                                      type="stops"
                                      onSelected={(val, selectedData: any) => {
                                        const stops = totalStops.map(
                                          (ts, j) => {
                                            if (j === index) {
                                              return {
                                                place_id: selectedData.place_id,
                                                description:
                                                  selectedData.description,
                                                latlng: {
                                                  lat: val.lat,
                                                  lng: val.lng,
                                                },
                                                waiting_time: ts.waiting_time,
                                              };
                                            }
                                            return ts;
                                          },
                                        );
                                        stops[index] = {
                                          place_id: selectedData.place_id,
                                          description: selectedData.description,
                                          latlng: {
                                            lat: val.lat,
                                            lng: val.lng,
                                          },
                                          waiting_time: null,
                                        };
                                        setTotalStops(stops);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                {/* sample drag end */}
              </div>
            )}
          </div>
          {/* For hourly service drop off location is not required */}
          {model?.service_type !== "hourly" && (
            <div className="d-flex w-100">
              <Form.Item
                label="Drop-off Location"
                tooltip="Select the destination for drop-off."
              ></Form.Item>
              <div className="w-100 overflow-hidden">
                <Form.Item>
                  {isLoaded && (
                    <PlacesAutoComplete
                      size="large"
                      width="100%"
                      value={
                        model.dropoff_location_key
                          ? {
                              label: model.dropoff_location_desc,
                              value: model.dropoff_location_key,
                            }
                          : null
                      }
                      defaultLocations={defaultLocations
                        .filter(
                          (loc: DefaultLocation) => loc.list_type === "dropoff",
                        )
                        .map((loc: DefaultLocation) => ({
                          label: loc.description,
                          value: loc.place_id,
                        }))}
                      type="dropoff"
                      onSelected={(val, selectedData: any) => {
                        dispatch(
                          reservationInputChange({
                            key: "dropoff_location_desc",
                            value: selectedData.description,
                          }),
                        );
                        dispatch(
                          reservationInputChange({
                            key: "dropoff_location_key",
                            value: selectedData.place_id,
                          }),
                        );
                        dispatch(
                          reservationInputChange({
                            key: "dropoff_lat",
                            value: val.lat,
                          }),
                        );

                        dispatch(
                          reservationInputChange({
                            key: "dropoff_lng",
                            value: val.lng,
                          }),
                        );
                      }}
                      placeholder="Your drop-off location"
                    />
                  )}
                </Form.Item>
              </div>
            </div>
          )}
          <div className="">
            {model?.service_type !== "hourly" && (
              <div className="col">
                <Form.Item
                  label="Transfer Type"
                  tooltip="Please indicate whether you prefer a one-way transfer or if you plan to return."
                >
                  <Select
                    size="large"
                    defaultValue={"one-way"}
                    onChange={(val) => {
                      dispatch(
                        reservationInputChange({
                          key: "transfer_type",
                          value: val,
                        }),
                      );
                      if (val === "one-way") {
                        setReturnTotalStops([]);
                        dispatch(
                          reservationInputChange({
                            key: "returnstops",
                            value: totalReturnStops,
                          }),
                        );
                      }
                    }}
                    options={[
                      { label: "One Way", value: "one-way" },
                      { label: "Return", value: "return" },
                    ]}
                  />
                </Form.Item>
              </div>
            )}
            <div className="col">
              <Form.Item
                label="Passengers"
                name="passenger"
                tooltip="Total number of passenger"
              >
                <Input
                  size="large"
                  placeholder="1"
                  type="number"
                  defaultValue="1"
                  maxLength={20}
                  name="passenger"
                  min={1}
                  max={20}
                  onChange={(val) => {
                    dispatch(
                      reservationInputChange({
                        key: "passenger",
                        value: val.target.value,
                      }),
                    );
                  }}
                />
              </Form.Item>
            </div>
          </div>
          {model?.service_type === "hourly" && (
            // (model.transfer_type === "return") disabled additional hour for return type
            <div className="col">
              <Form.Item
                label={
                  model.service_type === "hourly"
                    ? "Total Hours"
                    : "Extra Hours"
                }
                tooltip="Choose additional hours according to your requirements; the selected duration will impact the total price."
              >
                <Select
                  size="large"
                  defaultValue={4}
                  onChange={(val) => {
                    dispatch(
                      reservationInputChange({
                        key: "extra_hours",
                        value: val,
                      }),
                    );
                  }}
                  options={Array.from({ length: 12 }, (_, index) => ({
                    label: index + 4,
                    value: index + 4,
                  }))}
                />
              </Form.Item>
            </div>
          )}
        </Card>
        {/* return form */}

        {model?.service_type !== "hourly" &&
          model?.transfer_type === "return" && (
            // model.service_type !== "airport-transfer" &&
            <Card className="cardko mb20">
              <h3>Return Details</h3>
              <div className="row g-2">
                <div className="col-md-6">
                  <Form.Item label="Pickup Date" tooltip="Select a date">
                    <DatePicker
                      size="large"
                      disabledDate={(current) => {
                        let customDate = model.pickup_at;
                        return (
                          current && current < moment(customDate, "YYYY-MM-DD")
                        );
                      }}
                      // format='YYYY-MM-DD HH:mm A'
                      format="YYYY-MM-DD"
                      onChange={handleReturnDateChange}
                      onOk={() => {}}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item label="Pickup Time" tooltip="Select a time">
                    <TimePicker
                      size="large"
                      format="hh:mm A"
                      onChange={handleReturnTimeChange}
                      use12Hours
                      onOk={() => {}}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="d-flex w-100 gap-2">
                <div className={"w-100"}>
                  <div className="d-flex w-100">
                    <Form.Item
                      label="Return Pickup Location"
                      tooltip="Please provide the specific location where you would like us to arrange your pickup."
                    ></Form.Item>

                    <div className="w-100 overflow-hidden">
                      <Form.Item>
                        {isLoaded && (
                          <>
                            <div>
                              <PlacesAutoComplete
                                value={
                                  model?.return_pickup_location_key
                                    ? {
                                        label:
                                          model?.return_pickup_location_desc,
                                        value:
                                          model?.return_pickup_location_key,
                                      }
                                    : null
                                }
                                size="large"
                                width={
                                  model?.service_type !== "hourly"
                                    ? "100%"
                                    : "100%"
                                }
                                defaultLocations={defaultLocations
                                  .filter(
                                    (loc: DefaultLocation) =>
                                      loc.list_type === "pickup",
                                  )
                                  .map((loc: DefaultLocation) => ({
                                    label: loc.description,
                                    value: loc.place_id,
                                  }))}
                                type="pickup"
                                onSelected={(val, selectedData: any) => {
                                  dispatch(
                                    reservationInputChange({
                                      key: "return_pickup_location_desc",
                                      value: selectedData.description,
                                    }),
                                  );
                                  dispatch(
                                    reservationInputChange({
                                      key: "return_pickup_location_key",
                                      value: selectedData.place_id,
                                    }),
                                  );
                                  dispatch(
                                    reservationInputChange({
                                      key: "return_pickup_lat",
                                      value: val.lat,
                                    }),
                                  );

                                  dispatch(
                                    reservationInputChange({
                                      key: "return_pickup_lng",
                                      value: val.lng,
                                    }),
                                  );
                                }}
                                placeholder={"Your pickup location"}
                              />
                            </div>
                            {/* {model?.service_type === 'distance' && (
                                 <small>
                                   Click the <code>`Add Stop`</code> button to
                                   add multiple stops or way points
                                 </small>
                               )} */}
                          </>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt10">
                <div
                  className="d-flex justify-content-end"
                  // className="add-stop-btn"
                  style={{
                    paddingLeft: 0,
                    marginLeft: 0,
                    marginBottom: 0,
                    marginTop: -10,
                  }}
                >
                  <Button
                    type="text"
                    size="large"
                    block
                    style={{
                      // color: "rgba(255, 255, 255, 0.02)",
                      color: "rgb(249, 175, 0)",
                      background: "rgb(249, 255, 255,0.2)",
                      // border: "2px solid rgb(249, 175, 0)",
                    }}
                    onClick={() => {
                      setReturnTotalStops([
                        ...totalReturnStops,
                        {
                          place_id: "",
                          description: "",
                          latlng: { lat: 0, lng: 0 },
                          waiting_time: null,
                        },
                      ]);
                    }}
                    title="Add additional stops"
                  >
                    <i className="fa fa-plus" /> Add Stops
                  </Button>
                </div>
              </div>
              <div>
                {model?.service_type !== "hourly" && (
                  <div>
                    {/* sample drag  */}
                    <div className="  mx-auto ">
                      <DragDropContext onDragEnd={handleDragEndReturn}>
                        <Droppable droppableId="droppable">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className=" p-2 rounded-lg w-full "
                            >
                              {totalReturnStops.map((totalStop, index) => (
                                <Draggable
                                  key={index}
                                  draggableId={`input${index}`}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="flex items-center mb-2 bg-white p-2 rounded-lg shadow"
                                    >
                                      <FaGripVertical className="mr-2 cursor-grab" />
                                      <Button
                                        type="link"
                                        size="large"
                                        danger
                                        onClick={() => {
                                          const stops = [...totalReturnStops];

                                          // console.log(stops);
                                          stops.splice(index, 1);
                                          dispatch(
                                            reservationInputChange({
                                              key: "stops",
                                              value: [...stops],
                                            }),
                                          );
                                          setTotalStops([...stops]);
                                        }}
                                      >
                                        <div>
                                          <CloseOutlined />
                                        </div>
                                      </Button>
                                      <PlacesAutoComplete
                                        size="large"
                                        placeholder={"Add a stop point"}
                                        value={
                                          totalStop.place_id
                                            ? {
                                                label: totalStop.description,
                                                value: totalStop.place_id,
                                              }
                                            : null
                                        }
                                        // inputid={`stoploc${index}`}
                                        type="stops"
                                        onSelected={(
                                          val,
                                          selectedData: any,
                                        ) => {
                                          const stops = totalReturnStops.map(
                                            (ts, j) => {
                                              if (j === index) {
                                                return {
                                                  place_id:
                                                    selectedData.place_id,
                                                  description:
                                                    selectedData.description,
                                                  latlng: {
                                                    lat: val.lat,
                                                    lng: val.lng,
                                                  },
                                                  waiting_time: ts.waiting_time,
                                                };
                                              }
                                              return ts;
                                            },
                                          );
                                          stops[index] = {
                                            place_id: selectedData.place_id,
                                            description:
                                              selectedData.description,
                                            latlng: {
                                              lat: val.lat,
                                              lng: val.lng,
                                            },
                                            waiting_time: null,
                                          };
                                          setReturnTotalStops(stops);
                                        }}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                    {/* sample drag end */}
                  </div>
                )}
              </div>
              {model.service_type !== "hourly" && (
                <div className="d-flex">
                  <Form.Item
                    label="Return Drop-off Location"
                    tooltip="Select the destination for drop-off."
                  ></Form.Item>
                  <div className="w-100 overflow-hidden">
                    <Form.Item>
                      {isLoaded && (
                        <PlacesAutoComplete
                          size="large"
                          width="100%"
                          value={
                            model.return_dropoff_location_key
                              ? {
                                  label: model.return_dropoff_location_desc,
                                  value: model.return_dropoff_location_key,
                                }
                              : null
                          }
                          defaultLocations={defaultLocations
                            .filter(
                              (loc: DefaultLocation) =>
                                loc.list_type === "dropoff",
                            )
                            .map((loc: DefaultLocation) => ({
                              label: loc.description,
                              value: loc.place_id,
                            }))}
                          type="dropoff"
                          onSelected={(val, selectedData: any) => {
                            dispatch(
                              reservationInputChange({
                                key: "return_dropoff_location_desc",
                                value: selectedData.description,
                              }),
                            );
                            dispatch(
                              reservationInputChange({
                                key: "return_dropoff_location_key",
                                value: selectedData.place_id,
                              }),
                            );
                            dispatch(
                              reservationInputChange({
                                key: "return_dropoff_lat",
                                value: val.lat,
                              }),
                            );

                            dispatch(
                              reservationInputChange({
                                key: "return_dropoff_lng",
                                value: val.lng,
                              }),
                            );
                          }}
                          placeholder="Your drop-off location"
                        />
                      )}
                    </Form.Item>
                  </div>
                </div>
              )}
            </Card>
          )}
        <Button
          disabled={
            !model?.service_type ||
            !model?.pickup_at ||
            !model?.pickup_location_desc ||
            model.passenger < 1 ||
            model.passenger > 20 ||
            (model?.service_type !== "hourly" &&
              !model?.dropoff_location_desc) ||
            (model?.service_type === "distance" &&
              !model?.dropoff_location_desc) ||
            (model?.transfer_type === "return" && !model?.return_pickup_at) ||
            (model?.transfer_type === "return" &&
              !model?.return_pickup_location_desc) ||
            (model?.transfer_type === "return" &&
              !model?.return_dropoff_location_desc) ||
            (model?.transfer_type === "return" &&
              !model?.return_dropoff_location_desc)
          }
          block
          onClick={() => {
            checkTimeCorrect();
          }}
          size="large"
          type="primary"
        >
          Search &nbsp; <CaretRightOutlined className="next-icon-svg" />
        </Button>
      </div>
    </div>
  );
};
const error = (message: string, title: string) => {
  Modal.error({
    title: title,
    content: message,
  });
};
export default ReservationForm;
